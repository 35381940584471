import Vue from "vue"
import VueRouter from "vue-router"
import Redirect from "@/views/Redirect.vue"
import store from "@/store/index"

Vue.use(VueRouter)

let routes = [
	{
		// will match everything
		path: "*",
		component: () => import("../views/404.vue")
	},
	{
		path: "/",
		name: "Home",
		redirect: "/dashboard"
	},
	{
		path: "/dashboard",
		name: "Dashboard",
		layout: "dashboard",
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "dashboard" */ "../views/Dashboard.vue"),
		meta: { title: "首页" }
	},
	// {
	// 	path: "/layout",
	// 	name: "Layout",
	// 	layout: "dashboard",
	// 	component: () => import("../views/Layout.vue")
	// },
	{
		path: "/tables",
		name: "Tables",
		layout: "dashboard",
		component: () => import("../views/Tables.vue"),
		meta: { title: "表格" }
	},
	{
		path: "/billing",
		name: "Billing",
		layout: "dashboard",
		component: () => import("../views/Billing.vue"),
		meta: { title: "账单" }
	},
	{
		path: "/rtl",
		name: "RTL",
		layout: "dashboard-rtl",
		component: () => import("../views/RTL.vue"),
		meta: { title: "右对齐", layoutClass: "dashboard-rtl" }
	},
	{
		path: "/profile",
		name: "Profile",
		layout: "dashboard",
		component: () => import("../views/Profile.vue"),
		meta: { title: "用户信息", auth: true, layoutClass: "layout-profile" }
	},
	{
		path: "/sign-in",
		name: "Sign-In",
		component: () => import("../views/Sign-In.vue"),
		meta: { title: "登录" }
	},
	{
		path: "/sign-up",
		name: "Sign-Up",
		component: () => import("../views/Sign-Up.vue"),
		meta: { title: "注册", layoutClass: "layout-sign-up" }
	},
	{
		path: "/write",
		name: "Write",
		layout: "dashboard",
		component: Redirect,
		meta: { title: "创作" },
		children: [
			{
				path: "/",
				component: () => import("../views/Write.vue")
			},
			{
				path: "create",
				name: "WriteCreate",
				component: () => import("../views/WriteCreate.vue"),
				meta: { title: "生成", auth: true }
			}
		]
	},
	{
		path: "/role",
		name: "Role",
		layout: "dashboard",
		component: Redirect,
		meta: { title: "角色" },
		children: [
			{
				path: "/",
				component: () => import("../views/Role.vue")
			},
			{
				path: "chat",
				name: "RoleChat",
				component: () => import("../views/RoleChat.vue"),
				meta: { title: "聊天", auth: true }
			}
		]
	},
	{
		path: "/drawing",
		name: "Drawing",
		layout: "dashboard",
		component: Redirect,
		meta: { title: "绘画" },
		children: [
			{
				path: "/",
				component: () => import("../views/Drawing.vue")
			}
		]
	},
	{
		path: "/chat",
		name: "Chat",
		layout: "dashboard",
		component: () => import("../views/Chat.vue"),
		meta: { title: "AI会话", auth: true }
	},
	{
		path: "/recharge",
		name: "Recharge",
		layout: "dashboard",
		component: () => import("../views/Recharge.vue"),
		meta: { title: "充值中心", auth: true, layoutClass: "layout-recharge" }
	}
]

// Adding layout property from each route to the meta
// object so it can be accessed later.
function addLayoutToRoute(route, parentLayout = "default") {
	route.meta = route.meta || {}
	route.meta.layout = route.layout || parentLayout

	if (route.children) {
		route.children = route.children.map(childRoute => addLayoutToRoute(childRoute, route.meta.layout))
	}
	return route
}

routes = routes.map(route => addLayoutToRoute(route))

const router = new VueRouter({
	mode: "hash",
	base: process.env.BASE_URL,
	routes,
	scrollBehavior(to, from, savedPosition) {
		if (to.hash) {
			return {
				selector: to.hash,
				behavior: "smooth"
			}
		}
		return {
			x: 0,
			y: 0,
			behavior: "smooth"
		}
	}
})

router.beforeEach((to, from, next) => {
	if (to.meta.auth === true && !store.state.user.token) {
		store.commit("user/setLoginPopup", true)
	} else {
		next()
	}
})

router.afterEach((to, from) => {})

export default router
