import Vue from "vue"
import Vuex from "vuex"
import createPersistedState from "vuex-persistedstate"
import SecureLS from "secure-ls"

Vue.use(Vuex)

const modulesFiles = require.context("./modules", true, /\.js$/)
const modules = modulesFiles.keys().reduce((modules, modulePath) => {
	const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, "$1")
	const value = modulesFiles(modulePath)
	modules[moduleName] = value.default
	return modules
}, {})

const ls = new SecureLS({
	encodingType: "aes", // 加密类型
	isCompression: false, // 是否压缩
	encryptionSecret: "encryption" // PBKDF2值 加密密钥
})

const store = new Vuex.Store({
	plugins: [
		createPersistedState({
			key: "vuex",
			storage: {
				getItem: key => ls.get(key),
				setItem: (key, value) => ls.set(key, value),
				removeItem: key => ls.remove(key)
			},
			reducer(val) {
				return {
					app: {
						config: val.app.config
					},
					user: {
						token: val.user.token,
						userInfo: val.user.userInfo
					}
				}
			}
		})
	],
	modules
})

export default store
