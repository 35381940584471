<template>
	<!-- Layout Footer -->
	<a-layout-footer>
		<a-row type="flex">
			<a-col :span="24" :md="24">
				<!-- Footer Copyright Notice -->
				<!-- <p class="copyright">
					© 2021, made with
					<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path fill-rule="evenodd" clip-rule="evenodd" d="M3.17157 5.17157C4.73367 3.60948 7.26633 3.60948 8.82843 5.17157L10 6.34315L11.1716 5.17157C12.7337 3.60948 15.2663 3.60948 16.8284 5.17157C18.3905 6.73367 18.3905 9.26633 16.8284 10.8284L10 17.6569L3.17157 10.8284C1.60948 9.26633 1.60948 6.73367 3.17157 5.17157Z" fill="#111827" />
					</svg>
					by <a href="https://creative-tim.com">Creative Tim</a> for a better web.
				</p> -->
				<p class="copyright">
					<span class="mr-10" v-for="(item, index) in config.copyright" :key="'copyright_' + index">{{ item }}</span>
				</p>
				<!-- / Footer Copyright Notice -->
			</a-col>
			<!-- <a-col :span="24" :md="0" class="footer-control">
				<a-menu mode="horizontal">
					<a-menu-item>Creative Tim</a-menu-item>
					<a-menu-item>About Us</a-menu-item>
					<a-menu-item>Blog</a-menu-item>
					<a-menu-item>License</a-menu-item>
				</a-menu>
			</a-col> -->
		</a-row>
	</a-layout-footer>
	<!-- / Layout Footer -->
</template>

<script>
	import { mapState, mapGetters, mapMutations, mapActions } from "vuex"
	export default {
		data() {
			return {}
		},
		computed: {
			...mapGetters("app", ["config"])
		},
		created() {
			console.log(this.config, "0000000")
		},
		methods: {}
	}
</script>
